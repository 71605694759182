<template>
    <div>
        <div class="course_back">
            <img :src="source.back_img" alt=""/>
        </div>
        <div class="course_mask"></div>
        <div class="course_header">
            <p>{{source.title}}</p>
            <p>{{source.subtitle}}</p>
        </div>
        <div style="background: #fff;border-radius: 20px;margin-bottom: 40px;">
            <div style="height: 20px;"></div>
            <div class="lesson_course" v-for="(item,index) in contents">
                <p class="lesson_head">{{item.title}}<img v-if="index === 0" src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt=""></p>
                <div class="lesson_row" @click="lessonDetail(item)">
                    <div>
                        <p>{{item.subtitle}}</p>
                        <p>{{item.learned? '已学习': '未学习'}}|时长{{item.duration}}</p>
                    </div>
                    <div>
                        <van-icon name="arrow"  size="15" />
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 40px;"></div>
        </div>
    </div>
</template>

<script>
    import {Toast,Dialog} from 'vant';
    import {getQueryVariable} from "../../utils/public";
    export default {
        name: "Lesson",
        data(){
            return{
                course_id: 0,
                source:{},
                contents:[],
            }
        },
        mounted() {
            if(this.$route.query.course_id){
                this.course_id = this.$route.query.course_id;
            } else {
                this.course_id = getQueryVariable('course_id');
            }
            if(!this.course_id){
                Dialog({message:'未找到课程'}).then(()=>{ this.$router.replace('/')});
                return;
            }

            this.loadData();
        },
        methods:{
            loadData(){
                let that = this;
                this.post('/api/client/course/detail',{course_id:this.course_id}).then(result=> {
                    if(result.code === 0){
                        this.source = result.data.source;
                        this.contents = result.data.contents;
                    } else {
                        Dialog({ message: result.msg });
                        that.$router.replace('/');
                    }
                }).catch(e=>{
                    console.log(e);
                    Dialog({ message: '课程详情加载失败' });
                    that.$router.replace('/');
                });
            },
            lessonDetail(item){
                this.$router.push({ path: '/lessonDetail', query: { lesson_id: item.lesson_id }});
            }
        }

    }
</script>

<style scoped>

</style>